import React, { useState } from 'react';
import {sourceSet} from '../../helper';

function Avatar(props) {
	const [hovered, setHovered] = useState(false);
	const [clicked, setClicked] = useState(false);
	const [images, setImages] = useState([]);

	function toggleHover(){
		if (!hovered) {
			setHovered(hovered => !hovered);
		}
	}

	function toggleClick(){
		if (!clicked) {
			setClicked(clicked => !clicked);
		}
	}

  	return (
		<div
			className={`avatar ${hovered ? "avatar--hovered " : ""}`}
			onMouseEnter={toggleHover}
			onClick={toggleClick}
    	>
			<img
				src={props.avatar}
				srcSet={sourceSet(props.avatarSrcSet)}
				sizes="(min-width: 768px) 150px, 85px"
				alt={props.alt}
				height="85"
				width="85"
			/>
			<svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<linearGradient id="avatar-gradient" x1="0" y1="0" x2="0" y2="100%">
						<stop offset="0%" stopColor={`${clicked ? "var(--avatar-default)" : "var(--avatar-gradient-start)"}`} />
						<stop offset="100%" stopColor={`${clicked ? "var(--avatar-default)" : "var(--avatar-gradient-stop)"}`} />
					</linearGradient>
				</defs>
				<circle cx="40" cy="40" r="38" stroke="url(#avatar-gradient)"/>
			</svg>
		</div>
  	);
}

export default Avatar;