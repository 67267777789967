export const breakpointValuesMd = (defaultValue,mdValue) => {
    if (window.innerWidth < 768) {
        return defaultValue;
    } else {
        return mdValue;
    }
}

export const sourceSet = (images) => {
    return images && images.map ((object,i) =>(
        object
    ))
}
