import React from 'react';
import OverlayButton from '../OverlayButton';
import Button from '../Button';
import Job from '../Job';
import {breakpointValuesMd} from '../../helper';

function SectionBio(props) {
  	return (
		<div className="container pb-3 pt-md-3" style={{minHeight: breakpointValuesMd('661px','596px')}}>
			<div className="d-grid grid-col-gap-25p grid-md-tpl-col-1-2">
				<div className="d-flex justify-md-center pb-1">
					<OverlayButton 
						video={props.video}
						alt={props.firstName + " "+ props.lastName}
						name={props.firstName}
						avatar={props.avatar}
						avatarSrcSet={props.avatarSrcSet}
					/>
				</div>
				<div>
					<div className="lh-1-5 mb-1-5" style={{minHeight: "138px"}}>
						<h1 style={{minHeight: "50px"}} className="mb-1">
							<span className="h2 d-block">{props.firstName} {props.lastName}</span>
							<span className="h3 fw-normal d-block c-grey">{props.profession}</span>
						</h1>
						<p className="m-0">
							<span role="img" aria-label="location">📍</span> {props.location ? props.location : ''}<br/>
							<span role="img" aria-label="experience">🦋</span> {props.experience ? props.experience : ''}<br/>
							<span role="img" aria-label="education">🎓</span> {props.education ? props.education : ''}<br/>
						</p>
					</div>
					<div className="mb-2" style={{minHeight: "33px"}}>
						{props.links && props.links.map((object,i) =>(
							<Button 
								key={i}
								name={object.name}
								href={object.href}
								type={object.type}
								className={`${i === 0 ? "btn--primary" : ""} mr-0-5`}
							/>
						))}
					</div>
					{props.jobs && props.jobs.map((object,i) =>(
						<Job 
							key={i}
							start={object.start}
							end={object.end}
							position={object.position}
							company={object.company}
						/>
					))}
				</div>
			</div>		
		</div>			
  	);
}

export default SectionBio;