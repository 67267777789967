import React, { useState, useEffect } from 'react';
import Header from '../Header';
import SectionBio from '../SectionBio';
import SectionList from '../SectionList';

function StartPage() {

    const[data, setData] = useState([]);
    const[platforms, setPlatforms] = useState([]);
    const[skills, setSkills] = useState([]);
    const[jobs, setJobs] = useState([]);
    const[links, setLinks] = useState([]);

    useEffect(() => {
        fetchContent();
    },[]);

    const fetchContent = async () => {
        const url = 'martin-lehmann--v-006.json';
        const fetchData = await fetch(url);
        const data = await fetchData.json();
        setData(data);
        setSkills(data.skills);
        setPlatforms(data.platforms);
        setJobs(data.jobs);
        setLinks(data.links);
    };

    return (
        <main>
            <Header 
                pageName={`${data.firstName} <svg class="my-auto ml-0-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="15" height="15"><polygon fill="var(--blue)" points="29.62,3 33.053,8.308 39.367,8.624 39.686,14.937 44.997,18.367 42.116,23.995 45,29.62 39.692,33.053 39.376,39.367 33.063,39.686 29.633,44.997 24.005,42.116 18.38,45 14.947,39.692 8.633,39.376 8.314,33.063 3.003,29.633 5.884,24.005 3,18.38 8.308,14.947 8.624,8.633 14.937,8.314 18.367,3.003 23.995,5.884"></polygon><polygon fill="var(--dark)" points="21.396,31.255 14.899,24.76 17.021,22.639 21.428,27.046 30.996,17.772 33.084,19.926"></polygon></svg>`}
                hasBackButton={false}
            />
            <div className="overflow-hidden">
                <SectionBio
                    firstName={data.firstName}
                    lastName={data.lastName}
                    position={data.position}
                    company={data.company}
                    location={data.location}
                    profession={data.profession}
                    experience={data.experience}
                    education={data.education}
                    avatar={data.avatar}
                    avatarSrcSet={data.avatarSrcSet}
                    video={data.video}
                    jobs={jobs}
                    links={links}
                />
            <div className="container">
                <hr className="mt-0 mb-3" />
            </div>
            <SectionList
                sectionTitle="Platforms"
                sectionList={platforms} 
            />
            <SectionList
                sectionTitle="Skills"
                sectionList={skills} 
            />
            </div>
        </main>
    );
}

export default StartPage;