import React from 'react';

function Button(props) {
  	return (
		<a 	
			href={props.href}
			className={`btn ${props.className}`}
			target={`${props.type === 'external' ? "_blank" : ""}`}
			rel={`${props.type === 'external' ? "noopener noreferrer nofollow" : ""}`}
		>
			{props.name}
		</a>
	);
}

export default Button;