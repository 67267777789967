import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Section from '../Section';

function ContentPage({match}) {

	const[data, setData] = useState([]);
	const[pageContent, setPageContent] = useState([]);

	useEffect(() => {
		const fetchContent = async () => {
			const url = '..'+ match.url+'.json';
			const fetchData = await fetch(url);
			const data = await fetchData.json();
			setData(data);
			setPageContent(data.content);
		};
		fetchContent();
	},[match.url]);

	return (
		<main>
			<Header pageName={data.title} />
			<Section pageContent={pageContent} />
		</main>
	);
}

 export default ContentPage;