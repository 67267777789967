import React from 'react';

function Section(props) {
  return (
	<div className="container py-3">
		{props.pageContent.map((object,i) =>(
		<div key={i} className="d-grid grid-col-gap-25p grid-md-tpl-col-1-2">
			<h2 className="h3">{object.headline}</h2>
			<div dangerouslySetInnerHTML={{ __html: object.block }} />
		</div>
		))}
	</div>		
  );
}

export default Section;