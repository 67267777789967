import React, { useEffect } from 'react';
import { NavLink} from 'react-router-dom';

function Header(props) {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [props]);

	Header.defaultProps = {
		hasBackButton: true,   
	}
  
	return (
	<div className="position-sticky t-0 bg-black-o-65 z-10 blur-12" style={{minHeight: "32px"}}>
		<div className="container">
			<div className={`d-grid align-content-center ${props.hasBackButton ? "grid-tpl-col-1-1-1" : ""}`}>
				{props.hasBackButton &&
					<NavLink className="d-flex align-center" to="/">
						<svg width="20px" height="20px" viewBox="0 0 24 24" title="back" className="mr-0-25">
							<path fill="#fff" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
						</svg>
						<span className="d-none d-md-inline">Martin</span>
					</NavLink>
				}
				<div className="d-flex align-items-center justify-center fw-600" dangerouslySetInnerHTML={{ __html: props.pageName }} />
			</div>
		</div>
	</div>
	);
}

export default Header;