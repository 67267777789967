import React from 'react';
import {breakpointValuesMd} from '../../helper';

function SectionList(props) {
	return (
		<div className="pb-3" style={{minHeight: breakpointValuesMd('194px','178px')}}>
			<div className="container">
				<div className="d-grid grid-col-gap-25p grid-md-tpl-col-1-2">
					<h2 className="h3 mb-1">{props.sectionTitle}</h2>
					<ul className="d-grid grid-tpl-col-1-1 grid-col-gap-25p grid-md-col-gap-75p m-0 p-0 list-style-none">
					{props.sectionList && props.sectionList.map((object,i) =>(
						<li key={i} className="bb-1p pb-0-5 mb-0-5 mb-md-1 pb-md-1">{object}</li>
					))}
					</ul>
				</div>
			</div>			
		</div>
	);
}

export default SectionList;