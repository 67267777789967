import React from 'react';

function Job(props) {

	function duration(startDate,endDate){
		let stillCountingSymbol = "";
		let monthsUnit = "m";
		let yearsUnit = "";
		let years = "";

		if (endDate === ""){
			endDate = new Date().getTime();
			stillCountingSymbol = "+";
		}

		let duration = new Date(endDate).getTime() - new Date(startDate);
		let months = Math.floor((duration / (1000 * 60 * 60 * 24 ))/30);

		if (months < 1){
			months = "<1";
			stillCountingSymbol = "";
		}else if (months > 12 && (months%12 !== 0)){
			years = Math.floor(months/12);
			months = months%12;
			yearsUnit = "y ";
		}else if (months > 12 && (months%12 === 0)) {
			years = Math.floor(months/12);
			months = "";
			monthsUnit = ""
			yearsUnit = "y ";
		}else if (months === 12) {
			years = 1;
			months = "";
			monthsUnit = ""
			yearsUnit = "y";
		}
		return [years, yearsUnit, months, monthsUnit , stillCountingSymbol]
	}

  	return (
    	<div className="d-flex lh-1-5 mb-1" style={{minHeight: "75px"}}>
			<div className="d-flex w-75p h-75p br-50 b-1p align-center justify-center mr-1">
				{duration(props.start,props.end)}
			</div>
			<div>
				<span className="h3 d-block">{props.position}</span>
				<span className="d-block">{props.company}</span>
				<span className="d-block">{new Date(props.start).getFullYear()}</span>
			</div>
		</div>
	);
}

export default Job;