import React, { useState, useEffect  } from 'react';
import Avatar from'../../components/Avatar';
import './overlaybutton.css';
import {sourceSet} from '../../helper';

function OverlayButton(props) {

	const [opened, setOpened] = useState(false);

	const soundIconOn = <path fill="#000" d="M3 9v6h4l5 5V4L7 9H3zm7-.17v6.34L7.83 13H5v-2h2.83L10 8.83zM16.5 12c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77z"/>;
	const soundIconOff = <path fill="#000" d="M4.34 2.93L2.93 4.34 7.29 8.7 7 9H3v6h4l5 5v-6.59l4.18 4.18c-.65.49-1.38.88-2.18 1.11v2.06c1.34-.3 2.57-.92 3.61-1.75l2.05 2.05 1.41-1.41L4.34 2.93zM10 15.17L7.83 13H5v-2h2.83l.88-.88L10 11.41v3.76zM19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87 0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zm-7-8l-1.88 1.88L12 7.76zm4.5 8c0-1.77-1.02-3.29-2.5-4.03v1.79l2.48 2.48c.01-.08.02-.16.02-.24z"/>;
	const soundOffText = <span>TURN SOUND ON</span>;
	const videoAr = 0.5625;
	const body = document.body;
	const avatar = document.getElementById("overlay-trigger--avatar");
	const videoIntro = document.getElementById("video-intro");
	const progress = document.getElementById("progress");

	const[progressVal, setProgressVal] = useState("0");
	const[left, setLeft] = useState();
	const[top, setTop] = useState();
	const[soundIcon, setSoundIcon] = useState(soundIconOff);
	const[soundIconText, setSoundIconText] = useState(soundOffText);
	const requestId = React.useRef();

	var Hammer = require('hammerjs');
	const {vh,vw} = useWindowDimensions();
	const[containerWidth, setContainerWidth] = useState();
	const[containerMarginLeft, setContainerMarginLeft] = useState();

	function getContainerWidth() {
		let width = "100%";
		if (vw/vh > videoAr && vw > 420) {
			width = videoAr*vh;
		}
		return width;
	}

	function positionContainer(){
		if (vw/vh > videoAr && vw > 420) {
			setContainerWidth(getContainerWidth());
			setLeft("50%");
			setContainerMarginLeft(-(videoAr*vh/2));

		} else {
			setContainerWidth("100%");
			setLeft(0);
			setContainerMarginLeft(0);
		}
	}

	function getWindowDimensions() {
		const { innerWidth: vw, innerHeight: vh } = window;
		return {
			vw,
			vh
		};
	}

	function useWindowDimensions() {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}
			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);
		return windowDimensions;
	}

	function loop(vid, ava) {
	    requestId.current = undefined;
	    updateProgress(vid);
	    start(vid, ava);
	}

	function start(vid, ava) {
	    if (!requestId.current && (vid.currentTime !== vid.duration)) {
	    	vid.play();
	    	requestId.current = window.requestAnimationFrame(function() {
		        loop(vid, ava);
		    });
	    } else {
	    	closeOverlay(vid, ava);
	    }
	}

	function stop(vid) {
	    if (requestId.current) {
		    vid.pause();
	       	window.cancelAnimationFrame(requestId.current);
	       	requestId.current = undefined;
	    }
	}

	function reset(vid){
		vid.currentTime = 0;
	}

	function updateProgress(vid) {
		setProgressVal(Math.round((vid.currentTime / vid.duration) * 100))
	}

	function handleClickSound(vid){
		if(vid.muted){
			vid.muted = false;
			setSoundIcon(soundIconOn);
			setSoundIconText("");
		}else{
			vid.muted = true;
			setSoundIcon(soundIconOff);
			setSoundIconText(soundOffText);
		}
	}

	function getCoords(elem) {
		return {
			top: elem.getBoundingClientRect().top,
			left: elem.getBoundingClientRect().left
		}
	}

	function openOverlay(vid, ava){
		if (!opened) {
			setOpened(opened => !opened);
		}

		setLeft(getCoords(ava).left);
		setTop(getCoords(ava).top);
		positionContainer();
		body.classList.add('overlay-open');
		body.classList.add('overlay-lock');
		setTimeout(() => {body.classList.add('overlay-animation')}, 1);
		setTimeout(() => {start(vid, ava);}, 1);
	}

	function closeOverlay(vid, ava){
		stop(vid);
		setLeft(getCoords(ava).left);
		setContainerMarginLeft(0);
		body.classList.remove('overlay-animation');
		body.classList.remove('overlay-lock');
		setTimeout(() => {
			body.classList.remove('overlay-open');
			reset(vid);
			updateProgress(vid);
		}, 300);
	}

	useEffect(() => {
		const avatar = document.getElementById("overlay-trigger--avatar");
		const videoIntro = document.getElementById("video-intro");

		setContainerWidth(getContainerWidth());
		setLeft(getCoords(avatar).left);
		setTop(getCoords(avatar).top);

	   	var videoLeft = document.getElementById('video-left');
	   	var videoRight = document.getElementById('video-right');
		var hammertimeLeft = new Hammer(videoLeft);
		var hammertimeRight = new Hammer(videoRight);
		hammertimeLeft.get('swipe').set({ direction: Hammer.DIRECTION_DOWN });
		hammertimeLeft.get('tap').set();
		hammertimeRight.get('swipe').set({ direction: Hammer.DIRECTION_DOWN });
		hammertimeRight.get('tap').set();

		hammertimeLeft.on("swipe", function(ev) {
		    closeOverlay(videoIntro, avatar);
		});
		hammertimeLeft.on("tap", function(ev) {
		    reset(videoIntro);
		});
		hammertimeRight.on("swipe tap", function(ev) {
		    closeOverlay(videoIntro, avatar);
		});
  	},[]);

	return (
	    <div>
			<div className="overlay-trigger" id="overlay-trigger--avatar" role="button" onClick={() => openOverlay(videoIntro, avatar)}>
				<Avatar 
					alt={props.alt}
					avatar={props.avatar}
					avatarSrcSet={props.avatarSrcSet}
				/>
			</div>
			<div className="overlay">
				<div className="overlay-container" style={{left:left, top:top, maxWidth:containerWidth, marginLeft: containerMarginLeft}}>
					<div className="video-container lh-1">
						<div className="video-ui video-ui--row-top t-8">
							<progress id="progress" max="100" value={progressVal}>Progress</progress>
							<div className="video-avatar" onClick={() => closeOverlay(videoIntro, avatar)}>
								<img 
									src={opened ? props.avatar : 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='}
									srcSet={opened ? sourceSet(props.avatarSrcSet) : ''}
									sizes="32px"
									alt={props.alt}
									width="32"
									height="32" 
									className="br-50 mr-0-5"
								/>
								<div className="fw-600">{props.name}</div>
							</div>
							<div className="video-icon--close" role="button" onClick={() => closeOverlay(videoIntro, avatar)}>
								<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path fill="white" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
								</svg>
							</div>
						</div>
						<div className="video-ui video-ui--row-bottom">							
							<div className="video-btn-sound" role="button" onClick={() => handleClickSound(videoIntro, avatar)}>
								<svg className="svg-sound" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
									{soundIcon}
								</svg>
								{soundIconText}
							</div>
						</div>
						<div className="video-ui--view-50 l-0" id="video-left"></div>
						<div className="video-ui--view-50 r-0" id="video-right"></div>
						<video
							type="video/mp4"
							src={props.video}
							id="video-intro"
							playsInline
							muted
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OverlayButton;