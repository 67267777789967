import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import StartPage from'./components/StartPage';
import ContentPage from'./components/ContentPage';
import NoMatch from'./components/NoMatch';
import Footer from'./components/Footer';

function App() {
    return (
        <div className="d-grid grid-tpl-row-1-auto min-100vh">
            <Router>
                <Switch>
                    <Route path="/" exact component={StartPage} />
                    <Route path="/impressum" component={ContentPage}/>
                    <Route path="/datenschutz" component={ContentPage}/>
                    <Route component={NoMatch} />
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export default App;