import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {breakpointValuesMd} from '../../helper';

function Footer() {
  	return (
	    <footer className="pt-3 pb-4" style={{minHeight: breakpointValuesMd('192px','144px')}}>
			<div className="container">
				<div className="d-md-flex justify-md-space-between flex-md-row">
					<Link to="/" className="d-block mb-1 mb-md-0 fw-600">Martin Lehmann</Link>
					<ul className="d-grid grid-tpl-col-1-1 grid-col-gap-25p list-style-none m-0 p-0">
						<li>
							<NavLink to="/datenschutz" className="link-c-lightgrey" activeClassName="c-white">Datenschutz</NavLink>
						</li>
						<li>
							<NavLink to="/impressum" className="link-c-lightgrey" activeClassName="c-white">Impressum</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</footer>
  	);
}

export default Footer;